<template>
    <div class="my-auto" data-toggle="tooltip" data-placement="top" v-bind:class="[state]">
    </div>
</template>

<script>
export default {
    name: "StateIndicator",
    props: {
        state: String,
    }
}
</script>

<style scoped>
.offline {
    width: 5px;
    height: 5px;
    background: #E8271A;
    border-radius: 15px;
}

.online {
    width: 5px;
    height: 5px;
    background: #3ce81a;
    border-radius: 15px;
}

</style>