<template>
    <div class="container-fluid px-0 h-100">
        <NavBar/>
        <div class="col-sm-3 col-md-3 col-lg-3 col-xl-2 d-none d-sm-block sidebar bg-dark-nvx">
            <SideBar/>
        </div>

        <main class="col-sm-9 col-md-9 col-lg-9 col-xl-10 mx-0 ml-sm-auto px-0 LayoutSideBar__main">
            <slot/>
        </main>
    </div>
</template>

<script>
import NavBar from '@/components/layout/NavBar'
import SideBar from '@/components/layout/DevicesListSideBar'

export default {
    name: "LayoutSideBar",
    components: {
        NavBar,
        SideBar,
    }
}
</script>

<style scoped>
.LayoutSideBar__main {
    height:calc(100% - 56px);
}
</style>