<template>
    <div class="main LayoutDefault__main">
        <slot />
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "LayoutDefault",

    methods : {
        ...mapActions('users', ['setToken'])
    },
    
    mounted() {
        let parameters = this.$route.query;
        if (parameters['token']) {
            let token = parameters['token'];
            this.setToken(token);
        }
    }
}
</script>

<style scoped>

</style>