<template>
    <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="0" height="0" style="display: none;">
            <defs>
                <g id="invoxia-logo">
                    <path
                            fill="#FFFFFF"
                            d="M294.1,34.4c-27.2,0.1-45.2,19.1-45,47.4c0.3,23.2,20.8,42.8,45.2,42.5c29-0.3,45-22.5,45.3-42.9
					C340.1,53.3,321.2,34.3,294.1,34.4z M294.3,107.8c-16.6,0-28.4-12.1-28.4-29c0-16.2,11.9-27.9,28.3-28
					c16.3-0.1,28.4,11.8,28.4,27.9C322.6,95.7,310.9,107.8,294.3,107.8z"
                    />
                    <path
                            fill="#FFFFFF"
                            d="M97.8,34.4c-27.3,0.1-45.2,19.1-45,47.4V122h16.8V78.8c0-16.2,11.9-28,28.3-28.1
					c16.3-0.1,28.4,11.8,28.4,27.9V122l17-0.1l0-40.5C143.8,53.3,124.9,34.3,97.8,34.4z"
                    />
                    <ellipse fill="#FFFFFF" cx="23.8" cy="10.9" rx="11.6" ry="10.9"/>
                    <ellipse fill="#FFFFFF" cx="459.7" cy="10.9" rx="11.6" ry="10.9"/>
                    <path
                            fill="#FFFFFF"
                            d="M220.9,38.8l-25.1,61.9l-25.1-61.9c-0.6-1.6-2.1-2.6-3.8-2.6h-14.7l34.6,83.7c0.6,1.5,2.1,2.5,3.8,2.5H201
					c1.7,0,3.2-1,3.8-2.5l34.6-83.7h-14.7C223,36.2,221.5,37.2,220.9,38.8z"
                    />
                    <path
                            fill="#FFFFFF"
                            d="M16.3,36.4H0v16h16v69.8c5.5,0,10.7,0,16,0c0-16.1,0-50.1,0-70.2C31.9,43.4,24.9,36.4,16.3,36.4z"
                    />
                    <path
                            fill="#FFFFFF"
                            d="M580.9,36.6h-16.6v11.1c-16.5-18.5-44.6-16.2-59.6-2.4c-18.7,17.2-19.4,46.5-1.8,66
					c7.7,8.5,17.9,12.3,29.1,12.9c11.4,0.6,21.3-2.8,29.8-11.2c2.5,5.7,8.4,9.6,14.9,9.6c1,0,20,0,20,0v-16h-15.8V36.6z M535.7,107.7
					c-16.2-0.1-28-12.3-28-29.1c0-15.9,12.1-27.9,28.2-27.8c16.4,0.1,28.6,12.3,28.6,28.6C564.4,95.7,552.2,107.8,535.7,107.7z"
                    />
                    <path
                            fill="#FFFFFF"
                            d="M425.1,41.8l3.9-5.4h-9.4v0.1h-7.2c-2.3,0-4.4,1.1-5.7,2.9l-15.45,21.625L375.8,39.4
					c-1.3-1.8-3.4-2.9-5.7-2.9h-7.2v-0.1h-9.4l3.9,5.4l23.835,33.242L347.4,122.4h6.6c0.1,0,0.1,0,0.1,0l11.2-0.1
					c1.3,0,2.5-0.6,3.3-1.7l22.65-31.59l22.65,31.59c0.8,1.1,2,1.7,3.3,1.7l11.2,0.1c0,0,0,0,0.1,0h6.6l-33.835-47.358L425.1,41.8z"
                    />
                    <rect x="451.854" y="36.24" fill="#FFFFFF" width="15.904" height="85.726"/>
                </g>
            </defs>
        </svg>
        <b-navbar class="bg-dark-nvx" toggleable="lg" type="dark">
            <a class="navbar-brand" href="#">
                <svg viewBox="0 0 596.9 124.2">
                    <defs>
                        <linearGradient
                                id="logo_gradient_H"
                                gradientUnits="objectBoundingBox"
                                x1="0%"
                                y1="0%"
                                x2="0%"
                                y2="200%"
                        >
                            <stop stop-color="#47d7ac" offset="0.5"/>
                            <stop stop-color="#FFFFFF" offset="0.5"/>
                        </linearGradient>
                        <linearGradient
                                id="logo_gradient_M"
                                gradientUnits="objectBoundingBox"
                                x1="0%"
                                y1="0%"
                                x2="0%"
                                y2="200%"
                        >
                            <stop stop-color="#ff7f41" offset="0.5"/>
                            <stop stop-color="#FFFFFF" offset="0.5"/>
                        </linearGradient>
                        <linearGradient
                                id="logo_gradient_D"
                                gradientUnits="objectBoundingBox"
                                x1="0%"
                                y1="0%"
                                x2="0%"
                                y2="200%"
                        >
                            <stop stop-color="#ffbe00" offset="0.5"/>
                            <stop stop-color="#FFFFFF" offset="0.5"/>
                        </linearGradient>
                    </defs>
                    <use xlink:href="#invoxia-logo" class="logo_i"/>
                </svg>
            </a>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <ul class="navbar-nav ml-auto">
                    <b-nav-item-dropdown right ref="dropdown">
                        <template slot="button-content">{{getUserName}}</template>
                        <h6 class="dropdown-header">{{$t('version')}}: {{version}}</h6>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="https://www.invoxia.com">{{$t('shop')}}</a>
                        <div class="dropdown-divider"></div>
                        <!-- <div class="mx-4 center">
                            <select v-model="$i18n.locale" @change="onChange($event)">
                                <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">{{ lang }}</option>
                            </select>
                        </div> -->
                        <button class="dropdown-item" type="button" v-on:click="logout() && removeDevices()">{{$t('logout')}}</button>
                    </b-nav-item-dropdown>
                </ul>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<style lang="scss">
    @import "@/assets/scss/style.scss";
</style>

<script id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=bd28e588-5090-4bf6-9615-833fffb8d9d4"></script>

<script>
    import "@/assets/scss/style.scss";
    import moment from "moment";

    import { mapActions, mapGetters, mapMutations } from "vuex";

    export default {
        name: "NavBar",
        data() {
            return {
                version: "",
                langs: ["fr", "en", "es"]
            };
        },

        computed: {
            ...mapGetters('users', ['getUserName']),
        },

        methods: {
            ...mapActions('users', ['login']),
            ...mapActions('users', ['logout']),
            ...mapActions('devices', ['removeDevices']),
            ...mapActions('devices', ['removeSelectedDevice']),
            // login_state: function () {
            //     var self = this;
            //     HTTP.get("/api-auth/session/login/")
            //         .then(response => {
            //             var data = response.data;
            //             if (response.status == 200) {
            //                 self.username = data.user;
            //                 zE('webWidget', 'updateSettings', {
            //                     webWidget: {
            //                         helpCenter: {
            //                           suppress: true
            //                         },
            //                         contactForm: {
            //                             ticketForms: [
            //                                 {
            //                                     id: 360000143458,
            //                                     fields: [
            //                                         {id: 'email', prefill: {'*': self.username}},
            //                                         {id: 360006220438, prefill: {'*': true}}
            //                                     ]
            //                                 }
            //                             ],

            //                         }
            //                     }
            //                 });
            //             }
            //         })
            //         .catch(e => {
            //             this.errors = e;
            //         });
            // },
            hide_dropdown: function () {
                this.$refs.dropdown.hide(true);
            },
            isActiveTab: function (name) {
                var _name = this.$route.name;
                return name == _name;
            },
            onChange: function (event) {
                let _locale = event.target.value;
                moment.locale(_locale);
            }
        },
        mounted: function () {
            var self = this;
            this.login();
            // console.log("mount navbar");

            if (window._env_.ITP_VERSION) {
                self.version = window._env_.ITP_VERSION;
            }
        }
    };
</script>